<div class="panel panel-default">
    <div class="panel-body">
      <p>
        <b>access_token_expiration:</b> {{data.display_access_token_expiration}}
      </p>
      <p>
        <b>id_token_expiration:</b> {{data.display_id_token_expiration}}
      </p>
      <p>
        <b>access_token:</b> {{data.access_token}}
      </p>
      <p>
        <!-- <b>id_token:</b> {{id_token}} -->
      </p>
      <p>
        <b>name: </b>{{data.givenName}} {{data.familyName}}
      </p>
      <p>
        <b>email: </b>{{data.email_address}}
      </p>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onClickOk()">Ok</button>
  </div>