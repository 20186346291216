import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';

import { Host } from './host';
@Component({
  selector: 'app-admin-hosts',
  templateUrl: './admin-hosts.component.html',
  styleUrls: ['./admin-hosts.component.css']
})
export class AdminHostsComponent implements OnInit {
  baseUrl = "https://api.robaralisa.net/api/v1/";

  displayedColumns: string[] = ['icon', 'host', 'timestamp'];
  dataSource: Observable<Host[]>;
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    var headers = { 'Accept': 'text/json' };
    var currentTimestamp = new Date();
    this.dataSource = this.http.get<Host[]>(this.baseUrl + 'hosts', { headers })
      .pipe(
        map((res: Host[]) =>
        {
          return res.map(el => {
            let ts = new Date(el.timestamp + " UTC");
            el.timestamp = ts.toISOString();
            if (currentTimestamp.getTime() - ts.getTime() > 1000 * 3600) {
              el.indicator = 1;
            }
            return el;
          })
        }
        ));
  }

}
