export class UserProfile {
  givenName: string;
  familyName: string | undefined;
  id_token: string | undefined;
  access_token: string | undefined;
  id_token_expiration: Date;
  access_token_expiration: Date;
  email_address: string | undefined;
  picture: string | undefined;

  get display_id_token_expiration() {
    return this.formatTimestamp(this.id_token_expiration);
  }

  get display_access_token_expiration() {
    return this.formatTimestamp(this.access_token_expiration);
  }

  private formatTimestamp(date: Date) {
    return date == null ? '' : date.toString();
  }
}