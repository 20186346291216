import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {

    // Url of the Identity Provider
    issuer: 'https://accounts.google.com',
  
    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin + '/index.html',
  
    loginUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
  
    oidc: true,
  
    requestAccessToken: true,
  
    tokenEndpoint: 'https://www.googleapis.com/oauth2/v4/token',
    userinfoEndpoint: 'https://www.googleapis.com/oauth2/v3/userinfo',
    strictDiscoveryDocumentValidation: false,
  
    // The SPA's id. The SPA is registerd with this id at the auth-server
    clientId: '687612963465-mdcr04v2m08b9fnp0b0pa06vimmdcp60.apps.googleusercontent.com',
  
    // set the scope for the permissions the client should request
    // The first three are defined by OIDC. The 4th is a usecase-specific one
    scope: 'openid profile email',
  
    showDebugInformation: true,
  
    sessionChecksEnabled: false
  };
  
  function getBaseHref() {
    let base = document.querySelector('base');
    return base && base.href || '';
  }