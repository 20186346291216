import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminHostsComponent } from './admin-hosts/admin-hosts.component';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
  },
  {
    path: 'admin/hosts',
    component: AdminHostsComponent,
  },
  {
    path: '**', 
    component: AppComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
