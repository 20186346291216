<mat-card>
    <mat-card-header>
        <mat-card-title>Device Health</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="icon">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let rec">
                    <!-- <mat-icon class="critical" aria-hidden="false" aria-label="Critical">error</mat-icon> -->
                    <mat-icon *ngIf="rec.indicator==1" class="warning" aria-hidden="false" aria-label="Warning">warning</mat-icon>
                    <!-- <mat-icon class="info" aria-hidden="false" aria-label="Info">info</mat-icon> -->
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="host">
                <mat-header-cell *matHeaderCellDef>Host</mat-header-cell>
                <mat-cell *matCellDef="let rec">{{rec.host}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="timestamp">
                <mat-header-cell *matHeaderCellDef>Last Heartbeat</mat-header-cell>
                <mat-cell *matCellDef="let rec">{{rec.timestamp | date : 'MM/dd/YYYY hh:mm:ss a' }}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns:displayedColumns"></mat-row>
        </mat-table>
    </mat-card-content>
</mat-card>