import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { authConfig } from '../app.component';
import { UserProfile } from './user-profile';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  ngOnInit(): void {
  }

  loginFailed: boolean = false;
  userProfile: UserProfile;

  constructor(private oauthService: OAuthService, public dialog: MatDialog) {
    this.userProfile = new UserProfile();
    this.oauthService.configure(authConfig);
    this.oauthService.loadDiscoveryDocument().then(up => this.refreshProfile());
    // this.oauthService.setupAutomaticSilentRefresh();   
  }

  public login() {
    this.oauthService.initImplicitFlow();
    // this.oauthService.loadUserProfile().then(up => this.refreshProfile());
  }

  public logoff() {
    this.oauthService.logOut();
    this.refreshProfile();
  }

  private refreshProfile() {
    console.log("called refreshProfile()");
    if (this.isAuthenticated) {
      this.userProfile.access_token_expiration = new Date(this.oauthService.getAccessTokenExpiration());
      this.userProfile.access_token = this.oauthService.getAccessToken();
      this.userProfile.id_token_expiration = new Date(this.oauthService.getIdTokenExpiration());
      this.userProfile.givenName = this.getClaim('given_name');
      this.userProfile.familyName = this.getClaim('family_name');
      this.userProfile.id_token = this.oauthService.getIdToken();    
      this.userProfile.email_address = this.getClaim("email");
      this.userProfile.picture = this.getClaim("picture");
    } else {
      this.userProfile = new UserProfile();
      console.log("profile nullified");
    }
  }
  
  loadUserProfile(): void {
    console.log("loadUserProfile()");
    this.oauthService.loadUserProfile().then(up => this.openDialog(up));
  }
  
  openDialog(up: object): void {
    // this.userProfile = up;
    const dialogRef = this.dialog.open(LoginDialogComponent, {data: this.userProfile});
  }
  
  public get name() {
    return this.getClaim('given_name');
  }
  set requestAccessToken(value: boolean) {
    console.log("set requestAccessToken");
    this.oauthService.requestAccessToken = value;
    localStorage.setItem('requestAccessToken', '' + value);
  }

  get requestAccessToken() {
    return this.oauthService.requestAccessToken;
  }

  get isAuthenticated() {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()
  }

  private getClaim(claim: string) {
    var claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims[claim];
  }
}
